import {
  ArrowDownIcon,
  ChevronDownIcon,
  LanguageIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid"
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { useLocation } from "@reach/router"
import { getSearchUrl } from "../../utils/i18nUrls"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import clsx from "clsx"
import axios from "axios"

import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Dropdown: React.FC<{
  onChange: (params: QuestionTypes) => any
  type: QuestionTypes
}> = props => {
  return (
    <Menu as="div" className="relative block text-left">
      <div>
        <Menu.Button className="flex font-display w-full items-center justify-center gap-x-1.5 rounded-xl shadow-sm bg-white px-3 py-2  text-slate-900 ring-1 ring-inset ring-slate-200 hover:bg-slate-50">
          {props.type === QuestionTypes.WHAT_DOES_IT_MEANS && "Que veux dire ?"}
          {props.type === QuestionTypes.DOES_IT_IS_CORRECT &&
            "Est-ce correct ?"}
          {props.type === QuestionTypes.DOES_IT_SOUNDS_NATURAL &&
            "Est-ce que ça semble naturel ?"}
          {props.type === QuestionTypes.WHAT_ARE_THE_DIFFERENCES_BETWEEN &&
            "Quelles sont les différences ?"}

          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-slate-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 right-0 w-full font-display z-10 mt-2 text-center origin-top rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() =>
                    props.onChange(QuestionTypes.WHAT_DOES_IT_MEANS)
                  }
                  className={classNames(
                    active ? "bg-slate-100 text-slate-900" : "text-slate-700",
                    "block px-4 py-2 w-full "
                  )}
                >
                  Que veux dire ?
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() =>
                    props.onChange(QuestionTypes.DOES_IT_IS_CORRECT)
                  }
                  className={classNames(
                    active ? "bg-slate-100 text-slate-900" : "text-slate-700",
                    "block px-4 py-2 w-full "
                  )}
                >
                  Est-ce correct ?
                </button>
              )}
            </Menu.Item>
            {/* <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() =>
                    props.onChange(
                      QuestionTypes.WHAT_ARE_THE_DIFFERENCES_BETWEEN
                    )
                  }
                  className={classNames(
                    active ? "bg-slate-100 text-slate-900" : "text-slate-700",
                    "block px-4 py-2 w-full "
                  )}
                >
                  Quelle est la différence ?
                </button>
              )}
            </Menu.Item> */}
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() =>
                    props.onChange(QuestionTypes.DOES_IT_SOUNDS_NATURAL)
                  }
                  className={classNames(
                    active ? "bg-slate-100 text-slate-900" : "text-slate-700",
                    "block px-4 py-2 w-full "
                  )}
                >
                  Est-ce que ça semble naturel ?
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

type Response = {
  input: string
  error: null | null
  text: string
  words: {
    word: string
    explanation: string
    translation: string
    romaji: string
  }[]
  translation: string
}

enum QuestionTypes {
  WHAT_DOES_IT_MEANS = "WHAT_DOES_IT_MEANS",
  WHAT_ARE_THE_DIFFERENCES_BETWEEN = "WHAT_ARE_THE_DIFFERENCES_BETWEEN",
  DOES_IT_SOUNDS_NATURAL = "DOES_IT_SOUNDS_NATURAL",
  DOES_IT_IS_CORRECT = "DOES_IT_IS_CORRECT",
}

export const Question: React.FC<{
  onChangeState: "neutral" | "loading" | "success"
}> = props => {
  const [isLoading, setLoading] = useState(false)
  const [response, setResponse] = useState<string | null>(null)
  const [input, setInput] = useState<string>("")
  const [questionType, setQuestionType] = useState<QuestionTypes>(
    QuestionTypes.WHAT_DOES_IT_MEANS
  )

  const intl = useIntl()
  const location = useLocation()

  const onSubmit = async () => {
    props.onChangeState("loading")

    setLoading(true)
    const request = await axios.post(
      // "https://5001-marqueskevi-nipponizerc-prb9o2cuxqy.ws-eu95.gitpod.io/nipponizer/us-central1/api/question",
      "https://us-central1-nipponizer.cloudfunctions.net/api/question",
      {
        sentence: input,
        type: questionType,
      }
    )

    setResponse(request.data.answer)
    setLoading(false)
    props.onChangeState("success")
  }

  return (
    <>
      {
        <>
          <Dropdown
            type={questionType}
            onChange={type => setQuestionType(type)}
          />
          <div className=" relative mt-4 rounded-xl w-full  border border-slate-200 shadow-sm">
            <textarea
              name="q"
              className="bg-transparent text-lg h-32 px-4 font-display focus:outline-none border-none py-4 resize-none text-center font-normal w-full text-slate-900 placeholder:text-slate-300"
              placeholder={"Écrivez votre texte ici…"}
              onChange={e => setInput(e.target.value)}
              value={input || ""}
              // placeholder={intl.formatMessage({ id: "search/input/placeholder" })}
            />
            <div className="absolute bottom-0 right-0 font-display text-slate-300 p-4">
              {input.length}/256
            </div>
          </div>

          {!isLoading && (
            <button
              type="submit"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                onSubmit()
              }}
              className="flex font-display mt-4 w-full items-center justify-center gap-x-1.5 rounded-xl shadow-sm bg-white px-3 py-2  text-slate-900 ring-1 ring-inset ring-slate-200 hover:bg-slate-50"
            >
              Soumettre
            </button>
          )}
        </>
      }
      {isLoading && (
        <div className="mt-4">
          <div className="w-full bg-slate-100 h-24 animate-pulse rounded-lg"></div>
          <div className="flex space-x-2 items-center justify-center mt-2">
            <div className="w-24 h-24 bg-slate-100 animate-pulse rounded-lg"></div>
            <div className="w-24 h-24 bg-slate-100 animate-pulse rounded-lg"></div>
            <div className="w-24 h-24 bg-slate-100 animate-pulse rounded-lg"></div>
            <div className="w-24 h-24 bg-slate-100 animate-pulse rounded-lg"></div>
          </div>
        </div>
      )}
      {response && !isLoading && (
        <>
          <div className="border rounded-xl text-lg p-4 mt-4">
            <ReactMarkdown
              components={{
                p: props => <p {...props} className="mb-2" />,
                ul: props => (
                  <ul {...props} className="mb-2 list-disc list-inside" />
                ),
                li: props => <li {...props} />,
              }}
            >
              {response}
            </ReactMarkdown>
          </div>
        </>
      )}
    </>
  )
}
