import React, { useState } from "react"
import { useIntl } from "react-intl"
import { ArticleEntity } from "../../entities/ArticleEntity"
import {
  getBlogUrl,
  getPopularUrl,
  getPrivacyUrl,
  getTermsUrl,
} from "../../utils/i18nUrls"
import { Category } from "../Category/Category"
import { FooterLinks } from "../FooterLinks/FooterLinks"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { Image } from "../Image/Image"
import { Link } from "../Link/Link"
import { H1 } from "../Mdx/components"
import { MobileNavigation } from "../MobileNavigation/MobileNavigation"
import { SearchInput } from "../SearchInput/SearchInput"
import { StaticPageContainer } from "../StaticPageContainer/StaticPageContainer"
import { StaticPageHeading } from "../StaticPageHeading/StaticPageHeading"
import { AnalyzeTextArea } from "../AnalyzeTextArea/AnalyzeTextArea"
import { StaticImage } from "gatsby-plugin-image"
import clsx from "clsx"
import { graphql, useStaticQuery } from "gatsby"
import { Question } from "../Question/Question"

export const HomeSearch: React.FC<{ logo: any }> = props => {
  const intl = useIntl()
  const [state, changeState] = useState<"neutral" | "loading" | "success">(
    "neutral"
  )
  const query = useStaticQuery(graphql`
    query GetMascotteQuery {
      neutral: file(relativePath: { eq: "logo-base.svg" }) {
        id
        publicURL
      }
      happy: file(relativePath: { eq: "logo-happy.svg" }) {
        id
        publicURL
      }
      exiting: file(relativePath: { eq: "logo-exiting.svg" }) {
        id
        publicURL
      }
    }
  `)

  console.log(query)
  return (
    <>
      <section className="relative min-h-screen pt-6 pb-6 w-full">
        <div className="w-full px-4">
          <div className="max-w-2xl mt-8 lg:mt-8 mx-auto">
            <div className="text-center">
              <Image
                alt={props.logo.alt}
                src={query.neutral.publicURL}
                className={clsx(
                  "h-20 w-20 ",
                  state === "neutral" ? "inline-block" : "hidden"
                )}
              />

              <Image
                alt={props.logo.alt}
                src={query.exiting.publicURL}
                className={clsx(
                  "h-20 w-20 ",
                  state === "loading" ? "inline-block" : "hidden"
                )}
              />

              <Image
                alt={props.logo.alt}
                src={query.happy.publicURL}
                className={clsx(
                  "h-20 w-20 ",
                  state === "success" ? "inline-block" : "hidden"
                )}
              />
            </div>
            <div className="text-center mt-4">
              <h1 className="mx-auto max-w-3xl px-4 md:px-0 font-display text-4xl font-bold">
                Nipponizer
              </h1>
              <p className="text-lg">
                <FormattedMessage id="home/description" />
              </p>
            </div>
            <div className="mt-8">
              <Question onChangeState={changeState} />
            </div>

            <FooterLinks />
          </div>
        </div>
      </section>
      {/* <MobileNavigation /> */}
    </>
  )
}
