import clsx from "clsx"
import React from "react"
import { useIntl } from "react-intl"
import {
  getBlogUrl,
  getHomeUrl,
  getPrivacyUrl,
  getTermsUrl,
  getVersbUrl,
} from "../../utils/i18nUrls"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { Link } from "../Link/Link"

export const FooterLinks: React.FC<{
  alignLeft?: boolean
  obfuscated?: boolean
}> = props => {
  const intl = useIntl()

  return (
    <>
      {/* <ul
        className={clsx(
          "mt-10 flex  items-center font-normal font-display text-xs space-x-4",
          !props.alignLeft && "justify-center"
        )}
      >
        <li>
          <Link
            url={getVersbUrl(intl.locale, 1)}
            rel={props.obfuscated ? "obfuscated" : "follow"}
          >
            <FormattedMessage id="footer/links/verbs" />
          </Link>
        </li>
        <li>
          <Link
            url={getBlogUrl(intl.locale)}
            rel={props.obfuscated ? "obfuscated" : "follow"}
          >
            <FormattedMessage id="footer/links/blog" />
          </Link>
        </li>
        <li>
          <Link
            url={getPrivacyUrl(intl.locale)}
            rel={props.obfuscated ? "obfuscated" : "follow"}
          >
            <FormattedMessage id="footer/privacy" />
          </Link>
        </li>
        <li>
          <Link
            url={getTermsUrl(intl.locale)}
            rel={props.obfuscated ? "obfuscated" : "follow"}
          >
            <FormattedMessage id="footer/terms" />
          </Link>
        </li>
      </ul> */}
      {/* <ul
        className={clsx(
          "mt-8 flex items-center font-normal font-display text-xs space-x-4",
          !props.alignLeft && "justify-center"
        )}
      >
        <li>
          <Link
            url={getHomeUrl("fr")}
            rel={props.obfuscated ? "obfuscated" : "follow"}
          >
            Français
          </Link>
        </li>
        <li>
          <Link
            url={getHomeUrl("en")}
            rel={props.obfuscated ? "obfuscated" : "follow"}
          >
            English
          </Link>
        </li>
        <li>
          <Link
            url={getHomeUrl("es")}
            rel={props.obfuscated ? "obfuscated" : "follow"}
          >
            Español
          </Link>
        </li>
        <li>
          <Link
            url={getHomeUrl("it")}
            rel={props.obfuscated ? "obfuscated" : "follow"}
          >
            Italiano
          </Link>
        </li>
        <li>
          <Link
            url={getHomeUrl("pt")}
            rel={props.obfuscated ? "obfuscated" : "follow"}
          >
            Português
          </Link>
        </li>
      </ul> */}
    </>
  )
}
